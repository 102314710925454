<template>
  <div
    class="sidebar"
    :class="{'open' : sidebarVisible}">
      <div 
      v-for="item in sidebarItem" 
      :key="item.id"
      class="d-flex flex-column item"
      :class="sidebarItemActive === item.name ? 'item--active' : ''" 
      @click="changeContent(item)">
      <img :src="sidebarItemActive === item.name ? sidebarIcon : item.icon" alt="icon">
      <span class="font-10 font-roboto">
        {{item.name}}
      </span>
    </div>
 
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapState('home', [
      'sidebarVisible', 
      'sidebarItem', 
      'sidebarItemActive'
    ]),
    ...mapGetters('home', ['sidebarIcon'])
  },
  methods: {
    ...mapMutations('home', ['setSidebarItemActive']),

    changeContent(contentType){
      this.setSidebarItemActive(contentType.name)
      this.$router.push(contentType.url)
    }
  },
  created() {
    if(this.$route.name === 'Dashboard') {
        this.setSidebarItemActive('Pembelian')
    } else if (this.$route.name === 'Report' || this.$route.name === 'ReportAll') {
          this.setSidebarItemActive('Laporan')
    }

    else if (this.$route.name === 'Retur' || this.$route.name === 'Return') {
          this.setSidebarItemActive('Retur')
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar{
  min-width: 78px;
  width: 78px;
  padding: 0;
  height: 100%;
  padding-top: 100px;
  font-size: 12px;
  left: -78px;
  background: white;
  text-align: center;
  position: fixed;
  transition: .3s ease-in;
  top: 0;
  &.open {
    left: 0
  }
  .item{
    cursor: pointer;
    padding: 18px 0;

    img{
      width: 20px;
      margin: 0 auto 5px auto;
    }

    &--active{
      background: $primary-light;
      color: $primary;
    }
  }
}
</style>